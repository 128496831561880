// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  
  recaptcha: {
  siteKey: '6LfbhUQqAAAAAGknsuK0aLuPpCHBuwKgDJc2wxZQ',
},

  production: false,
  // apiUrl: 'https://93d3-106-213-83-14.ngrok.io/'
  // apiUrl: 'https://5757-2401-4900-1c2c-4d29-e03c-58a4-501d-220b.ngrok.io/'
  // apiUrl: 'http://35.180.97.53/'
  // apiUrl: 'http://35.180.192.151/'
 // http://127.0.0.1:8000/
 // apiUrl: 'https://spotlightdz.dz/spotlight/',
 // baseUrl:'https://spotlightdz.dz/'

   apiUrl: 'https://spotlightdz.dz/spotlight/',
  baseUrl:'http://127.0.0.1:8000/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
