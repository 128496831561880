<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Blog</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Blog -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                    </div>
                    <span>Job skills. 12-09-2020</span>
                    <h3><a routerLink="/blog-details">The Internet Is A Job Seeker Most Crucial Success </a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                    </div>
                    <span>Career advice. 11-09-2020</span>
                    <h3><a routerLink="/blog-details">Today From Connecting With Potential Employers</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                    </div>
                    <span>Future plan. 10-09-2020</span>
                    <h3><a routerLink="/blog-details">We’ve Weeded Through Hundreds Of Job Hunting</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog4.jpg" alt="Blog"></a>
                    </div>
                    <span>Job skills. 13-09-2020</span>
                    <h3><a routerLink="/blog-details">We Do Friendly Behave With Our All Employee</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog5.jpg" alt="Blog"></a>
                    </div>
                    <span>Job skills. 14-09-2020</span>
                    <h3><a routerLink="/blog-details">We Are Very Dedicated To Do Our Work</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog6.jpg" alt="Blog"></a>
                    </div>
                    <span>Job skills. 15-09-2020</span>
                    <h3><a routerLink="/blog-details">How We Do Select To Our Beloved Employers</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->