<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Banner -->
<!-- <div class="banner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <h1 class="heading-size mt-3"><span class="heading-small"> FIRST PLATEFORM WHICH</span> <br>
                                <span class="heading-big">IMPROVE</span> <br><span class="heading-small"> YOUR BRAND AND YOUR </span><br>
                            <span class="heading-big2">CONTENT</span></h1>
                            <div class="row mt-5">
                                <div class="col-md-2"></div>
                                <div class="d-grid gap-2 col-md-8 mx-auto">
                                    <a class="btn btn-block home-button">{{"Get Started" | uppercase}}</a>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<section class="outter option1">
  <section class="video-container">
    <!-- <video src="./assets/videos/vid1.mp4" autoplay loop [muted]="'muted'" id="video"></video> -->
    <div class="callout container">
      <h1 class="heading-size mt-3">
        <span class="heading-small">{{"FIRST ALGERIAN CREATORS" | translate}}</span><br>
        <span class="heading-big">{{"PLAT" | translate}}</span> <br>
        <span class="heading-small"> {{"WE HELP BRANDS AND CREATORS CONNECT" | translate}}</span><br>
        <span class="heading-big">{{"TOGETHER" | translate}}</span>
      </h1>
      <div class="row mt-5 start-btn">
        <svg *ngIf="!auth.currentUserValue" width="200" height="55" xmlns="http://www.w3.org/2000/svg"
          style="cursor: pointer;" (click)="registration()">
          <rect x="0" y="0" width="100%" height="55" rx="25" ry="25" fill="#db5e70" />
          <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16"
            font-family="Arial, sans-serif">
            <a class="fw-bold fs-4">{{'Get Started' |translate}}</a>
          </text>
        </svg>
        <!-- <a class="btn home-button" (click)="registration()">{{"GET STARTED" | translate}}</a> -->
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</section>
<!-- <div class="banner-container">
  <img src="assets/img/home-one/bg_home.webp" alt="Banner Image" class="banner-image">

  <div class="text-container">
    <p class="heading-small mb-1">{{"FIRST ALGERIAN CREATORS" | translate}}</p>
    <h1 class="heading-big">{{"PLATFORM" | translate}}</h1>
    <p class="heading-small mb-1">{{"WE HELP BRANDS AND" | translate}}<br>{{"CREATORS CONNECT" | translate}}</p>
    <h1 class="heading-big">{{"TOGETHER" | translate}}</h1>
  </div>
</div> -->
<!-- End Banner -->

<!-- Work -->
<section class="work-area pt-100"
  style="background-image: url('assets/img/home-one/300M_views_bg.webp'); background-size: cover;">
  <div class="container">
    <!-- <div class="section-title mt-0">
      <span class="sub-title">Working Process</span>
      <h2 style="visibility: hidden;" translate>See How It Works</h2>
    </div> -->

    <div class="row position-relative">
      <div class="col-md-6 col-lg-6 ">
        <img src="assets/svgs/300m.svg" alt="Partner" id="img" width="75%">
        <!-- <div class="card view-info">
                    <div class="card-body text-center">
                        <span class="heading-small" >{{"MORE THAN" | translate}}</span><br>
                        <span class="heading-small2" style="color:#db5e70">300 M</span><br>
                        <span class="heading-small" >{{"VIEWS" | translate}}</span><br>
                        <span class="heading-small" >{{"ACROSS" | translate}}</span><br>
                        <span class="heading-small" style="color:#db5e70">{{"ALL CAMPAIGNS" | translate}}</span><br>
                    </div>
                </div> -->
        <!-- <div class="col-sm-6 col-lg-6"> -->
        <div class="view-btn py-2 my-2">
          <svg width="150" height="50" xmlns="http://www.w3.org/2000/svg" class="svg-container">
            <rect x="0" y="0" width="150" height="50" rx="25" ry="25" fill="#db5e70" />
            <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16"
              font-family="Arial, sans-serif">
              <a style="cursor: pointer;" class="fw-bold" (click)="registration()">{{'View More' |translate}}</a>
            </text>
          </svg>
        </div>
        <!-- <button class="btn btn-block view-button">{{'View More' | translate}}</button> -->
        <!-- </div> -->
      </div>
      <div class="col-md-6 col-lg-6">
        <img src="./assets/img/home-one/300M_side.png">
        <div class="row g-2">
          <!-- <div class="col-md-6">
                        <div class="row g-2">
                        <div class="col-md-12">
                        <div class="view-reel">
                            <img src="./assets/img/home-one/location1.jpg" class="one-image w-100 shadow-1-strong">
                        </div>
                        </div>
                        </div>
                        <div class="row g-2" style="--bs-gutter-x: 0.5rem;">
                        <div class="col-md-12">
                        <div class="view-reel" style="margin-top: 10px">
                            <img src="./assets/img/home-one/location1.jpg" class="two-image w-100 shadow-1-strong">
                        </div>
                        </div>
                        </div>
                    </div> -->
          <!-- <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="other-image">
                                    <img src="./assets/img/home-one/location1.jpg" class="three-image w-100 shadow-1-strong">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="other-image">
                                    <img src="./assets/img/home-one/location1.jpg"
                                        class="three-image w-100 shadow-1-strong">
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
      <!-- <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>STEP 03</span>
                    <h3>Apply For Job</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div> -->
    </div>

    <div class="row" style="margin-top: 25px;">
      <div class="col-md-12">
        <!-- <div class="section-title text-center">
          <h2 style="color: #db5e70">{{"TRUSTED BY" | translate}}</h2>
        </div> -->
        <h4 class="mb-3 text-center" style="color:#db5e70; font-style: normal;">{{"Trusted by" | translate}}</h4>
        <div class="partner-area2">
          <div class="container">
            <div class="partner-slider owl-theme owl-carousel">
              <div class="partner-item">
                <img src="assets/svgs/fifa.svg" alt="Partner">
                <img src="assets/svgs/fifa.svg" alt="Partner">
              </div>

              <div class="partner-item">
                <img src="assets/svgs/fiba.svg" alt="Partner">
                <img src="assets/svgs/fiba.svg" alt="Partner">
              </div>

              <div class="partner-item">
                <img src="assets/svgs/Ooredoo_logo.svg" alt="Partner">
                <img src="assets/svgs/Ooredoo_logo.svg" alt="Partner">
              </div>


              <div class="partner-item">
                <img src="assets/svgs/anghami.svg" alt="Partner">
                <img src="assets/svgs/anghami.svg" alt="Partner">
              </div>

              <div class="partner-item">
                <img src="assets/svgs/oppo.svg" alt="Partner">
                <img src="assets/svgs/oppo.svg" alt="Partner">
              </div>
              <div class="partner-item">
                <img src="assets/svgs/yassir.svg" alt="Partner">
                <img src="assets/svgs/yassir.svg" alt="Partner">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Work -->

<!-- Talent -->
<section class="work-area">
  <div class="container">
    <div class="section-title mt-5">
      <div class="row">
        <div class="col-md-5 col-lg-5 mx-auto">
          <img src="assets/svgs/talent.svg" alt="Partner">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-3 mb-3">
        <a class="talent-reel" >
          <img loading="lazy" [src]="profile_one?.profile_pic" class="one-image-reel w-100 shadow-1-strong" #img
            (error)="img.src = 'assets/svgs/frame.svg'">
          <div class="overlay">{{profile_one?.first_name}}</div>
        </a>

          <!-- <div class="row">
            <div class="col-md-6 col-lg-3 mb-3">
              <a class="talent-reel" (click)="view_details(profile_one)">
                <img loading="lazy" [src]="profile_one?.profile_pic" class="one-image-reel w-100 shadow-1-strong" #img
                  (error)="img.src = 'assets/svgs/frame.svg'">
                <div class="overlay">{{profile_one?.first_name}}</div>
              </a> -->
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <a class="talent-reel" >
          <img loading="lazy" [src]="profile_two?.profile_pic" class="two-image-reel w-100 shadow-1-strong" #img2
            (error)="img2.src = 'assets/svgs/frame.svg'">
          <div class="overlay">{{profile_two?.first_name}}</div>
        </a>
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <a class="talent-reel" >
          <img loading="lazy" [src]="profile_three?.profile_pic" class="one-image-reel w-100 shadow-1-strong" #img3
            (error)="img3.src = 'assets/svgs/frame.svg'">
          <div class="overlay">{{profile_three?.first_name}}</div>
        </a>
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <a class="talent-reel" >
          <img loading="lazy" [src]="profile_four?.profile_pic" class="two-image-reel w-100 shadow-1-strong" #img4
            (error)="img4.src = 'assets/svgs/frame.svg'">
          <div class="overlay">{{profile_four?.first_name}}</div>
        </a>
      </div>
      <!-- <div class="col-sm-6 col-lg-6 align-self-center" style="margin-top: 30px">
                <div class="card view-info">
                    <div class="card-body text-center">
                        <span class="heading-small" style="color:#000">{{"MORE THAN" | translate}}</span><br>
                        <span class="heading-small" style="color:#db5e70">20 M</span><br>
                        <span class="heading-small" style="color:#000">{{"FOLLOWERS" | translate}}</span><br>
                        <span class="heading-small" style="color:#000">{{"IN ONLY" | translate}}</span><br>
                        <span class="heading-small" style="color:#db5e70">{{"ONE PLATFORM" | translate}}</span>
                    </div>
                </div>
            </div> -->
      <!-- <div class="col-sm-6 col-lg-6" style="margin-top: 30px">
                <div class="row g-2">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngFor="let data of image_array">
                        <a class="talent-reel" (click)="view_details(data)">
                            <img src="assets/svgs/frame.svg" alt="Partner">
                            <img [src]="data.profile_pic" class="two-image-reel w-100 shadow-1-strong" #img
                            (error)="img.src = 'assets/img/blank_profile.png'">
                            <div class="overlay">{{data.first_name}}&nbsp;{{data.last_name}}</div>
                        </a>
                    </div>
                </div>
            </div> -->
      <!-- <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>STEP 03</span>
                    <h3>Apply For Job</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div> -->
    </div>

  </div>
</section>


<section class="plans__container">
  <div class="plans">
    <div class="plansHero">
      <h1 class="plansHero__title">Nos Packs</h1>
      <!-- <p class="plansHero__subtitle">No contracts. No suprise fees.</p> -->
    </div>
    <div class="planItem__container">
      <!--free plan starts -->
      <div class="planItem planItem--free">

        <div class="card">
          <div class="card__header">
            <div class="card__icon symbol symbol--rounded"></div>
            <h2>Gratuit</h2>
          </div>
          <!-- <div class="card__desc">Accédez gratuitement aux profils des créateurs et bénéficiez d'un premier aperçu des services.</div> -->
        </div>

        <div class="price">0 DZD <span>
          HT</span> <span>
          {{"Annuel" |translate}}</span></div>

        <ul class="featureList">
          <li>{{"Accès aux profils des créateurs." |translate}}</li>
          <li class="disabled">{{"Formation Zoom préenregistrée." |translate}}</li>
          <li class="disabled">{{"Formation Zoom préenregistrée" |translate}}</li>

          <li class="disabled">    {{"Formation en présentiel + Study cases de campagnes clients." |translate}}</li>
          <li class="disabled">    {{"Formation en présentiel + Study cases de campagnes clients" |translate}}</li>

          <li class="disabled">{{"Création d'une campagne sur la plateforme." |translate}}</li>
          <li class="disabled">{{"Reminder emails to influencers to boost the campaign." |translate}}</li>

          <li class="disabled">{{"Statistiques pré-campagne" |translate}}</li>


          <li class="disabled">{{"Statistiques pré-campagne." |translate}}</li>
          <li class="disabled">{{"Reporting de la campagne." |translate}}</li>

        </ul>

        <button class="button" *ngIf="!auth.currentUserValue" (click)="registration()">{{"Get Started" |translate}}</button>
      </div>
      <!--free plan ends -->

      <!--pro plan starts -->
      <div class="planItem planItem--pro">
        <div class="card">
          <div class="card__header">
            <div class="card__icon symbol symbol--rounded"></div>
            <h2>Basic</h2>
          </div>
          <!-- <div class="card__desc">Bénéficiez d'un accès plus complet avec la formation Zoom préenregistrée et l'accès aux profils des créateurs.</div> -->
        </div>

        <div class="price">5 000 DZD  <span>
         HT</span> <span>
          {{"Annuel" |translate}}</span></div>

        <ul class="featureList">
          <li>{{"Accès aux profils des créateurs." |translate}}</li>
          <li >{{"Formation Zoom préenregistrée." |translate}}</li>
          <li >{{"Formation Zoom préenregistrée" |translate}}</li>

          <li class="disabled">    {{"Formation en présentiel + Study cases de campagnes clients." |translate}}</li>
          <li class="disabled">    {{"Formation en présentiel + Study cases de campagnes clients" |translate}}</li>

          <li class="disabled">{{"Création d'une campagne sur la plateforme." |translate}}</li>
          <li class="disabled">{{"Reminder emails to influencers to boost the campaign." |translate}}</li>

          <li class="disabled">{{"Statistiques pré-campagne" |translate}}</li>

          <li class="disabled">{{"Statistiques pré-campagne." |translate}}</li>
          <li class="disabled">{{"Reporting de la campagne." |translate}}</li>

        </ul>

        <button class="button" *ngIf="!auth.currentUserValue" (click)="registration()">{{"Get Started" |translate}}</button>
      </div>
      <!--pro plan ends -->
<!--pro plan starts -->
<div class="planItem planItem--pro">
  <div class="card">
    <div class="card__header">
      <div class="card__icon symbol"></div>
      <h2>VIP</h2>
    </div>
    <!-- <div class="card__desc">Profitez d'un accès privilégié avec une formation en présentiel, des études de cas clients, et la possibilité de créer des campagnes sur la plateforme.</div> -->
  </div>

  <div class="price">50 000 DZD <span>
    {{"HT" |translate}}</span> <span>
    {{"Annuel" |translate}}</span></div>

    <ul class="featureList">
      <li>{{"Accès aux profils des créateurs." |translate}}</li>
      <li >{{"Formation Zoom préenregistrée." |translate}}</li>
      <li >{{"Formation Zoom préenregistrée" |translate}}</li>

      <li >    {{"Formation en présentiel + Study cases de campagnes clients." |translate}}</li>
      <li >    {{"Formation en présentiel + Study cases de campagnes clients" |translate}}</li>

      <li >{{"Création d'une campagne sur la plateforme." |translate}}</li>
      <li >{{"Reminder emails to influencers to boost the campaign." |translate}}</li>

      <li class="disabled">{{"Statistiques pré-campagne" |translate}}</li>

      <li class="disabled">{{"Statistiques pré-campagne." |translate}}</li>

      <li class="disabled">{{"Reporting de la campagne." |translate}}</li>

    </ul>

    <button class="button" *ngIf="!auth.currentUserValue" (click)="registration()">{{"Get Started" |translate}}</button>
  </div>
<!--pro plan ends -->

      <!--entp plan starts -->
      <div class="planItem planItem--entp">
        <div class="card">
          <div class="card__header">
            <div class="card__icon"></div>
            <h2>VIP +</h2>
          </div>
          <!-- <div class="card__desc">Bénéficiez d'un accès exclusif avec une formation avancée, des statistiques détaillées avant campagne, et un reporting complet de vos campagnes.</div> -->
        </div> 

        <div class="price">150 000 DZD <span style="color: white;"> HT</span><span style="color: white;"> {{"Annuel" |translate}}</span></div>

        <ul class="featureList">
          <li>{{"Accès aux profils des créateurs." |translate}}</li>
          <li>{{"Formation Zoom préenregistrée." |translate}}</li>
          <li>{{"Formation Zoom préenregistrée" |translate}}</li>

          <li >    {{"Formation en présentiel + Study cases de campagnes clients." |translate}}</li>
          <li >    {{"Formation en présentiel + Study cases de campagnes clients" |translate}}</li>

          <li>{{"Création d'une campagne sur la plateforme." |translate}}</li>
          <li >{{"3 Reminder emails to influencers to boost the campaign." |translate}}</li>

          <li >{{"Statistiques pré-campagne" |translate}}</li>

          <li >{{"Statistiques pré-campagne." |translate}}</li>
          <li   >{{"Reporting de la campagne." |translate}}</li>

        </ul>

        <button class="button" *ngIf="!auth.currentUserValue" (click)="registration()">{{"Get Started" |translate}}</button>
      </div>
      <!--entp plan ends -->
      
    </div>
  </div>
</section>

<!-- talent and for who start -->
<section class="big-section" appLazyLoadImage>
  <section class="work-area pt-100">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-6 align-self-center">
          <div class="row">
            <div class="col-md-12">
              <ngb-carousel class="pt-100" [showNavigationIndicators]="showNavigationIndicators">
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper m-auto" style="max-width: 50%; height: 390px; overflow: hidden;">
                    <img src="assets/img/job-details1.webp" class="slide-image"
                      style="width: 100%; height: 100%; object-fit: cover;" alt="Random first slide" />
                  </div>
                </ng-template>
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper m-auto" style="max-width: 50%; height: 390px; overflow: hidden;">
                    <img src="assets/img/job-details2.webp" class="slide-image"
                      style="width: 100%; height: 100%; object-fit: cover;" alt="Random second slide" />
                  </div>
                </ng-template>
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper m-auto" style="max-width: 50%; height: 390px; overflow: hidden;">
                    <img src="assets/img/job-details3.webp" class="slide-image"
                      style="width: 100%; height: 100%; object-fit: cover;" alt="Random third slide" />
                  </div>
                </ng-template>
              </ngb-carousel>

              <!-- <ngb-carousel class="mt-3 mb-3" [showNavigationIndicators]="showNavigationIndicators">
                            <ng-template ngbSlide>
                                <div class="picsum-img-wrapper" style="max-width: 60%; margin: auto;">
                                    <img src="assets/img/job-details1.jpg" class="slide-image"
                                        alt="Random first slide" />
                                </div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <div class="picsum-img-wrapper" style="max-width: 60%; margin: auto;">
                                    <img src="assets/img/job-details2.jpg" class="slide-image"
                                        alt="Random second slide" />
                                </div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <div class="picsum-img-wrapper" style="max-width: 60%; margin: auto;">
                                    <img src="assets/img/job-details3.jpg" class="slide-image"
                                        alt="Random third slide" />
                                </div>
                            </ng-template>
                        </ngb-carousel> -->
              <!-- <div class="details-inner">
                            <h3>Image Gallery</h3>
                            <div class="job-details-slider owl-theme owl-carousel">
                                <div class="slider-item">
                                    <img src="assets/img/job-details1.jpg" alt="Details">
                                </div>
                                <div class="slider-item">
                                    <img src="assets/img/job-details2.jpg" alt="Details">
                                </div>
                                <div class="slider-item">
                                    <img src="assets/img/job-details3.jpg" alt="Details">
                                </div>
                            </div>
                        </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="about-content pt-100">
            <div class="section-title">
              <h2 style="color: #481652;">{{"WHAT WE DID" | translate}}</h2>
              <h2 style="color: #db5e70">{{"Ca marche" | translate}}</h2>
            </div>
            <p class="body-text" translate>  
              Inscrivez-vous sur notre plateforme en tant que marque ou créateur. Les marques lancent des campagnes et reçoivent des candidatures, tandis que les créateurs postulent aux opportunités qui les inspirent. Une fois sélectionnés, ils collaborent pour produire du contenu authentique et impactant. Les marques suivent ensuite les performances en temps réel pour mesurer l’impact de leur campagne.  
          </p>
          
            
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- For Who -->
  <section class="work-area mb-5 pt-100">
    <div class="container">
      <div class="section-title mt-0">
        <!-- <span class="sub-title">Working Process</span> -->
        <h2 class="text-center">{{"FOR WHO" | translate}} ?</h2>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="portfolio p-2">
            <!-- <h3>Portfolio</h3> -->

            <div class="row g-2">
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/for who logos-10.svg" alt="Portfolio"></div>
              </div>
              
              
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/canon.svg" alt="Portfolio"></div>
              </div>
              
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/jumbo.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/Ooredoo_logo.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/yassir.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/for who logos-16.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/for who logos-17.svg" alt="Portfolio"></div>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/for who logos-24.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/for who logos-23.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/for who logos-22.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/syoch.svg" alt="Portfolio"></div>
              </div>
              
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/lg.svg" alt="Portfolio"></div>
              </div>
              <div class="col align-self-center">
                <div class="img"><img src="assets/svgs/for who logos-18.svg" alt="Portfolio"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-- End For Who -->
  <!-- 
  
  <section class="work-area pt-100">
    <div class="container pt-100">
      <div class="section-title mt-0">
            <span class="sub-title">Working Process</span>
            <h2>{{"CASE STUDY" | translate}}</h2>
        </div> 
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-md-6 col-lg-4">
          <video controls #videoPlayer height="60%" width="100%" height="" style="object-fit: cover;" controls>
            <source src="./assets/img/vid.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-md-6 col-lg-6 pt-3">
          <span class="body-text">
            <strong class="fs-3 fw-bold" translate style="color: #db5e70;">Tailored influencer campaigns</strong>
            <p translate>We begin with a thorough analysis of our clients' values, charters, markets, and goals, customizing each campaign to faithfully reflect the essence of the company. Every detail is considered to ensure perfect alignment with the brand's identity, placing authenticity at the core of our actions and preserving the brand's integrity. We carefully select content creators and partners based on their alignment with our clients' values, thus favoring partnerships that allow for an authentic connection with the target audience.</p>
            <p translate>Our team develops customized strategies, using a thoughtful approach to maximize the impact of each campaign and select the most effective tactics. Thanks to our tailored approach, our clients enhance their online presence and strengthen their brand image. Influence campaigns generate increased engagement and measurable results, thereby improving our clients' visibility and credibility on digital platforms.</p>
            
          </span>
        </div>
      </div>
    </div>
  </section>
-->





  <section class="work-area pt-100">
    <div class="container pt-100">
      <!-- <div class="section-title mt-0">
            <span class="sub-title">Working Process</span>
            <h2>{{"CASE STUDY" | translate}}</h2>
        </div> -->
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-md-6 col-lg-4">
          <video controls poster="./assets/img/thumbnail.jpg" #videoPlayer height="60%" width="100%" height="" style="object-fit: cover;" controls>
            <source src="./assets/img/vid.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-md-6 col-lg-6 pt-3">
          <span class="body-text">
            <strong class="fs-3 fw-bold" translate style="color: #db5e70;">Tailored influencer campaigns</strong>
            <br><br>
            <p translate>Collaborate with Influencers to Boost Your Brand Are you looking to give your brand a boost and reach new heights in terms of visibility and engagement? Join our community of innovative brands who have discovered the power of collaborating with influencers. As a platform dedicated to influencers and brands, we facilitate partnerships between the two worlds to create impactful and authentic campaigns. Whether you're a growing startup or an established large company, our carefully selected influencers can help you achieve your marketing goals creatively and effectively.</p>
            <br>
            <p translate> Discover how our tools and expertise can help you: Increase brand awareness Generate qualified traffic to your website Boost sales and customer loyalty Create original and engaging conten that resonates with your target audience Join us today and explore the countless possibilities of collaboration with passionate and authentic influencers. Together, let's build the future of your brand.</p>
            
          </span>
        </div>
      </div>
    </div>
  </section>
  <!-- End Case Study -->






</section>
<!-- talent and for who end 

<section class="work-area pt-100">
  <div class="container pt-100">
 
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-md-6 col-lg-4">
        <video #videoPlayer height="60%" width="100%" height="" style="object-fit: cover;" controls>
          <source src="./assets/img/vid.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      
      <div class="col-md-6 col-lg-6 pt-3">
        <span class="body-text">
          <strong class="fs-3 fw-bold" translate style="color: #db5e70;">Tailored influencer campaigns</strong>
          <br><br>
          <p translate>Collaborate with Influencers to Boost Your Brand Are you looking to give your brand a boost and reach new heights in terms of visibility and engagement? Join our community of innovative brands who have discovered the power of collaborating with influencers. As a platform dedicated to influencers and brands, we facilitate partnerships between the two worlds to create impactful and authentic campaigns. Whether you're a growing startup or an established large company, our carefully selected influencers can help you achieve your marketing goals creatively and effectively.</p>
          <br>
          <p translate> Discover how our tools and expertise can help you: Increase brand awareness Generate qualified traffic to your website Boost sales and customer loyalty Create original and engaging conten that resonates with your target audience Join us today and explore the countless possibilities of collaboration with passionate and authentic influencers. Together, let's build the future of your brand.</p>
          
        </span>
      </div>
    </div>
  </div>
</section>-->
<!-- End Case Study -->

<!-- Blog -->
<section class="blog-area pb-30 pt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <span class="body-text">
          <strong class="fs-3 fw-bold" translate style="color: #db5e70;">FINDING A SUITABLE INFLUENCER PROFILE ON
            INSTAGRAM</strong>
           <p translate>
            A quality influencer already has a well-established image in their field and a community that has chosen to
            follow them
            over others. It is important to remember that content creators are authorities in their respective domains.
            Collaborating with the right influencer will make your product placement much more relevant, as opposed to
            traditional
            advertising. The more impactful your campaign with an influencer, the more the collaboration's content will
            be shared by
            the influencer's community on social media. This will expand your audience circle even further! It is
            therefore
            important to look at the statistics and verify them to ensure the influencer is of high quality and genuine!
          </p>
          <p translate>
            The relevant influencer for your brand helps promote your product. It is therefore essential that they align
            with your
            image. It would be quite challenging to collaborate with an influencer passionate about hunting if you are
            launching a
            brand new vegan product line.
          </p>
          <p translate>
            Hence, it is crucial to establish search criteria. These foundations will keep you on track during your
            search.
            Remember, the content created as a result of this collaboration will be a joint effort between you and the
            influencer.
            The influencer will rightfully aim to offer quality content to their followers.
          </p>
          <p translate>Use an influencer tool to find Instagram influencers</p>
          <p translate>
            For businesses that run multiple campaigns per month, it has become nearly impossible to do without a tool
            that allows
            for easy discovery and contact of influencers. This task alone can be extremely time-consuming if you have
            to conduct
            the research manually for each influencer campaign. The operation of a tool like Spotlight is simple to
            understand and
            use. First, it provides you with direct access to influencers from all around the globe. Then, it allows you
            to refine
            your search to find the most relevant Instagram influencer for your campaign. You will have access to all
            statistical
            data, such as verified followers (real followers), engagement rates, community interests, location, and the
            contact
            information of the chosen influencer(s) to promote your brand or your client's brand.
          </p>
        </span>
      </div>
      <div class="col-md-6">
        <img src="./assets/img/home-one/finding_influencer.webp" width="100%" style="object-fit: cover;"
          alt="">
      </div>
    </div>
  </div>
</section>
<!-- End Blog -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->