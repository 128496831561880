import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AuthApiService } from '../../services/auth-api.service';
import { MustMatch } from '../../shared/validations/passwordValidator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  
  formOpen: boolean = false;
  is_influencer: boolean = false;
  is_company: boolean = false;
  register_form: FormGroup;
  influencer_id;
  influencer_custom_id;
  company_id;
  company_custom_id;
  isNewPasswordText = false
  isNewConfPasswordText = false
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    if (this.formOpen) {
      if (this.is_influencer) {
        this.influencer_submit();
      } else if (this.is_company) {
        this.company_submit();
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastrService,
    private api: AuthApiService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.register_form = this.fb.group({
      first_name: ['', [Validators.required, Validators.maxLength(120), Validators.pattern("^[a-zA-ZÀ-ÿ ’”']+$")]],
      last_name: ['', [Validators.required, Validators.maxLength(120)]],
      email: ['', [Validators.required, Validators.email]],
      // mobile: ['', [Validators.required, Validators.pattern('^(\+[\d]{1,5}|0)?[7-9]\d{9}$')]],
      mobile: ['', [Validators.required]],
      social_link: ['', ''],
      position: ['', ''],
      company: ['', ''],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'), Validators.maxLength(20)]],
      confirm_password: ['', [Validators.required]],
      terms_condition: ['', [Validators.requiredTrue]]
    },
      {
        validator: MustMatch('password', 'confirm_password')
      } as AbstractControlOptions);
      this.influencer_click();
      // console.log(this.formOpen);
      
  }

  toggleNewPassword(){
    this.isNewPasswordText = ! this.isNewPasswordText
  }
  toggleNewConfPassword(){
    this.isNewConfPasswordText = ! this.isNewConfPasswordText
  }

  influencer_click() {
    this.formOpen = true;
    this.is_influencer = true
    this.is_company = false
    this.change_to_company()
  }
  company_click() {
    this.formOpen = true;
    this.is_company = true
    this.is_influencer = false
    this.change_to_company();
  }

  change_to_company() {
    if(this.is_company == true) {
      this.register_form.get('position').setValidators([Validators.required, Validators.maxLength(120)])
      this.register_form.get('position').updateValueAndValidity()
      this.register_form.get('company').setValidators([Validators.required, Validators.maxLength(120)])
      this.register_form.get('company').updateValueAndValidity()
      this.register_form.get('social_link').clearValidators()
      this.register_form.get('social_link').updateValueAndValidity()
    } else {
      this.register_form.get('position').clearValidators()
      this.register_form.get('position').updateValueAndValidity()
      this.register_form.get('company').clearValidators()
      this.register_form.get('company').updateValueAndValidity()
      this.register_form.get('social_link').setValidators([Validators.required])
      this.register_form.get('social_link').updateValueAndValidity()
    }
  }

  influencer_submit() {
    this.markFormGroupTouched(this.register_form)
    if (this.register_form.invalid) {
      return;
    }
    let form_data = new FormData();
    form_data.append('first_name', this.register_form.get('first_name').value);
    form_data.append('last_name', this.register_form.get('last_name').value);
    form_data.append('email', this.register_form.get('email').value);
    form_data.append('mobile', this.register_form.get('mobile').value);
    form_data.append('social_link', this.register_form.get('social_link').value);
    form_data.append('password', this.register_form.get('password').value);
    this.api.register1(form_data).subscribe((res: any) => {
      if(res.status === 'success') {
        // localStorage.setItem('token', res.token)
        // localStorage.setItem('influencer_data', JSON.stringify(res.data))
        this.influencer_id = res.data.id;
        this.influencer_custom_id = res.data.custom_id;
        this.get_influencer_otp(res.data.email)
        console.log(res)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.message
        })
      }
    })
  }

  get_influencer_otp(data) {
    let form_data = new FormData()
    form_data.append('email', data)
    this.api.get_otp(form_data).subscribe((res: any) => {
      if(res.status == 'success') {
        let param = btoa(encodeURIComponent(JSON.stringify({'custom_id': this.influencer_custom_id, 'email': data})))
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
        this.router.navigate(['/otp-email'], {queryParams: {data:param}, skipLocationChange: true})
        // .then(() => {
        //   this.router.navigate(['/otp-email'], {queryParams: {data:param}, skipLocationChange: true})
        // })
      }
    })
  }

  getCompany_otp(data) {
    let f_data = new FormData();
    f_data.append('email', data);
    this.api.getCompanyOtp(f_data).subscribe((res: any) => {
      if (res.status == 'success') {
        let param = btoa(encodeURIComponent(JSON.stringify({ 'id': this.company_id, 'custom_id': this.company_custom_id, 'email': data })))
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
        this.router.navigate(['/otp-email-company'], { queryParams: { data: param }, skipLocationChange: true })
        // .then(() => {
        //   this.router.navigate(['/otp-email-company'], { queryParams: { data: param }, skipLocationChange: true })
        // })
      }
    })
  }

  company_submit() {
    this.markFormGroupTouched(this.register_form)
    if (this.register_form.invalid) {
      return;
    }
    const form_data = new FormData();
    form_data.append('first_name', this.register_form.get('first_name').value);
    form_data.append('last_name', this.register_form.get('last_name').value);
    form_data.append('email', this.register_form.get('email').value);
    form_data.append('mobile', this.register_form.get('mobile').value);
    form_data.append('company_name', this.register_form.get('company').value);
    form_data.append('position', this.register_form.get('position').value);
    form_data.append('password', this.register_form.get('password').value);
    form_data.append('confirm_password', this.register_form.get('confirm_password').value);

    this.api.company_register(form_data).subscribe((res: any) => {
      if (res.status == 'success') {
        this.getCompany_otp(res.data.email);
        // localStorage.setItem('token', res.token);
        // localStorage.setItem('company_data', JSON.stringify(res.data));
        this.company_id = res.data.company_id;
        this.company_custom_id = res.data.company_custom_id

        // console.log(res);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.message
        })
      }
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.register_form.controls;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
